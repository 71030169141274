let baseUrl = '',
	imgBaseUrl = '',
	imgCertBaseUrl = '',
	videoBaseUrl = '',
	websiteBaseUrl = '',
	officialWebsiteUrl = ''

if (process.env.NODE_ENV == 'development') {
	// baseUrl = 'http://ls2020.qicp.vip/api/'
	// baseUrl = 'http://www.ddfddf.com/api/'
	baseUrl = 'http://localhost:8080/api/'
	imgBaseUrl = 'https://ddfchinanew-1310653466.cos.ap-guangzhou.myqcloud.com/'
	imgCertBaseUrl = 'https://ddfchinanew-1310653466.cos.ap-guangzhou.myqcloud.com/e-cert/'
	videoBaseUrl = 'https://designv.oss-cn-beijing.aliyuncs.com/'
	websiteBaseUrl = 'http://localhost:8083/#/'
	officialWebsiteUrl = 'https://www.ddfddf.com/'
} else if (process.env.NODE_ENV == 'production') {
	baseUrl = 'https://www.ddfddf.com/api/'
	imgBaseUrl = 'https://ddfchinanew-1310653466.cos.ap-guangzhou.myqcloud.com/'
	imgCertBaseUrl = 'https://ddfchinanew-1310653466.cos.ap-guangzhou.myqcloud.com/e-cert/'
	videoBaseUrl = 'https://designv.oss-cn-beijing.aliyuncs.com/'
	websiteBaseUrl = 'https://dstar.ddfddf.com/#/'
	officialWebsiteUrl = 'https://www.ddfddf.com/'
}

export {
	baseUrl,
	imgBaseUrl,
	imgCertBaseUrl,
	videoBaseUrl,
	websiteBaseUrl,
	officialWebsiteUrl
}