import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Index",
    redirect: '/dragon_star'
  }, {
    path: "/login",
    name: "Login",
    component: () =>
      import ('../views/Login.vue'),
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: () =>
      import ('../views/ResetPassword.vue')
  },
  // 龙腾之星大赛
  {
    path: '/dragon_star',
    name: 'DragonStar',
    component: () =>
      import ('../views/Home.vue'),
    redirect: '/dragon_star/index',
    children: [{
        path: 'index',
        name: 'StarIndex',
        component: () =>
          import ('../views/Index.vue'),
      },{
        path: 'sign_up',
        name: 'StarSignUp',
        component: () =>
          import ('../views/SignUp.vue'),
      }, {
        path: 'about',
        name: 'StarAbout',
        component: () =>
          import ('../views/StarNav.vue'),
        redirect: '/dragon_star/about/index',
        children: [
          {
            path: 'index',
            name: 'AboutIndex',
            component: () =>
              import ('../views/subPages/Index.vue'),
          }, {
            path: 'organization',
            name: 'AboutOrganization',
            component: () =>
              import ('../views/subPages/Organization.vue'),
          }, {
            path: 'leader',
            name: 'AboutLeader',
            component: () =>
              import ('../views/subPages/Leader.vue'),
          }, {
            path: 'award_type',
            name: 'AboutAwardType',
            component: () =>
              import ('../views/subPages/AwardType.vue'),
          }, {
            path: 'guide',
            name: 'StarGuide',
            component: () =>
              import ('../views/subPages/Guide.vue'),
          }, {
            path: 'review_process',
            name: 'StarReviewProcess',
            component: () =>
              import ('../views/subPages/ReviewProcess.vue'),
          }, {
            path: 'award_setting',
            name: 'StarAwardSetting',
            component: () =>
              import ('../views/subPages/AwardSetting.vue'),
          }, {
            path: 'review_organization',
            name: 'StarReviewOrganization',
            component: () =>
              import ('../views/subPages/ReviewOrganization.vue'),
          }, {
            path: 'review_criteria',
            name: 'StarReviewCriteria',
            component: () =>
              import ('../views/subPages/ReviewCriteria.vue'),
          }, {
            path: 'download',
            name: 'StarDownload',
            component: () =>
              import ('../views/subPages/Download.vue')
          }, {
            path: 'feedback',
            name: 'StarFeedback',
            component: () =>
              import ('../views/subPages/Feedback.vue')
          }, {
            path: 'exemption',
            name: 'StarExemption',
            component: () =>
              import ('../views/subPages/Exemption.vue'),
          }, {
            path: 'contact',
            name: 'StarContact',
            component: () =>
              import ('../views/subPages/Contact.vue'),
          }, {
            path: 'publicity',
            name: 'StarPublicity',
            component: () =>
              import ('../views/Update.vue')
          }, {
            path: 'download',
            name: 'StarDownload',
            component: () =>
              import ('../views/Update.vue')
          }
        ]
      }, 
      // 证书下载
      {
        path: "/certificate",
        name: "Home",
        component: () =>
          import ("../views/cert/Home.vue"),
        redirect: '/certificate/nav',
        children: [{
          path: "nav",
          name: "CertificateNav",
          component: () =>
            import ("../views/cert/Nav.vue")
        }, {
          path: "student",
          name: "CertificateStudent",
          component: () =>
            import ("../views/cert/Student.vue")
        }, {
          path: "teacher",
          name: "CertificateTeacher",
          component: () =>
            import ("../views/cert/Teacher.vue")
        }, {
          path: "organization",
          name: "CertificateOrganization",
          component: () =>
            import ("../views/cert/Organization.vue")
        }, {
          path: "volunteer",
          name: "CertificateVolunteer",
          component: () =>
            import ("../views/cert/Volunteer.vue")
        }]
      },
      {
        path: "/next",
        name: "Next",
        component: () =>
          import ("../views/cert/Next.vue")
      }
    ]
  },
];

const router = new VueRouter({
  routes
});

export default router;