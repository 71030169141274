import {
  imgBaseUrl,
  imgCertBaseUrl
} from '@/config/env'
import provinceCity from '@/assets/data/ProvinceCity.json';


export default {
  methods: {
    // 获取城市列表
    gGetProvincList() {
      return provinceCity;
    },
    // 是否登录
    gIsLogin() {
      let loginTime = window.localStorage.getItem('login_time'),
        now = new Date().getTime();
      return loginTime && Number(loginTime) + 86400000 > now;
    },
    // 组装文件类路径
    // path   文件名
    // style  图片类文件尺寸格式 1. origin原文件 2.phone 3. h250
    getImageUrl(path, style) {
      let url = imgBaseUrl + path;
      // if (!path) {
      //   path = 'IMG/123.jpg';
      // }

      if (!style || style == 'origin') {
        return url;
      } else {
        return url + "/" + style;
      }
    },
    getVideoUrlQCloud(path) {
      return imgBaseUrl + path;
    },
    // 证书文件路径
    getCertImageUrl(path, style) {
      let url = imgCertBaseUrl + path;
      // if (!path) {
      //   path = 'IMG/123.jpg';
      // }

      if (!style || style == 'origin') {
        return url;
      } else {
        return url + "/" + style;
      }
    },
    // //图片居中显示
    // gCenterImg(e, f) { //f: 1-按短边显示， 2-按长边显示
    //   let img = e.currentTarget,
    //     parent = img.parentNode,
    //     iw = img.width,
    //     ih = img.height,
    //     imgWH = iw / ih,
    //     pw = parent.clientWidth,
    //     ph = parent.clientHeight,
    //     parentWH = pw / ph,
    //     flag = f == 2 ? parentWH / imgWH : imgWH / parentWH;
    //   if (flag < 1) {
    //     img.style.height = 'auto';
    //     img.style.marginLeft = 0;
    //     img.style.width = '100%';
    //     img.style.marginTop = (ph - pw / imgWH) / 2 + 'px';
    //   } else {
    //     img.style.width = 'auto';
    //     img.style.marginTop = 0;
    //     img.style.height = '100%';
    //     img.style.marginLeft = (pw - ph * imgWH) / 2 + 'px';
    //   }
    // }
  }
}